<template>
    <div>
        <ts-page-title
            :title="$t('itemCompensate.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('itemCompensate.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div>
                    <Button type="info" ghost @click="addNew">{{
                        $t('addNew')
                    }}</Button>
                </div>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        v-model="search"
                        search
                        :placeholder="$t('itemCompensate.searchCompensateItem')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="custom_keyword">
                    {{
                        row.item_custom_rule
                            ? row.item_custom_rule.custom_rule
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="paid_on_cycle">
                    <span>
                        <span v-if="row.paid_on_cycle == 1">First Cycle</span>

                        <span v-if="row.paid_on_cycle == 2">Second Cycle</span>
                    </span>
                </template>
                <template slot-scope="{ row }" slot="is_taxable">
                    <span>
                        <Icon
                            v-if="row.is_taxable"
                            type="ios-checkmark text-success"
                            size="30"
                        />
                        <Icon
                            v-else
                            type="ios-close-circle-outline text-danger"
                            size="20"
                        />
                    </span>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('itemCompensate.pageTitle')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>
<script>
import FromAction from './form.vue'
import { mapState } from 'vuex'
import { debounce } from 'lodash'

export default {
    name: 'item-compensate',
    components: {
        FromAction
    },
    data () {
        return {
            loading: false,
            width: 'width: 300px',
            showForm: false
        }
    },
    computed: {
        ...mapState('payroll/itemCompensate', ['resources', 'pagination']),
        search: {
            get () {
                return this.$store.state.payroll.itemCompensate.search
            },
            set (newValue) {
                this.$store.commit(
                    'payroll/itemCompensate/SET_SEARCH',
                    newValue
                )
                this.$store.commit('payrollI/itemCompensate/RESET_CURRENT_PAGE')
            }
        },

        columns () {
            return [
                {
                    title: this.$t('itemCompensate.compensateItem'),
                    key: 'compensate_item',
                    sortable: true
                },
                {
                    title: this.$t('itemCompensate.customRule'),
                    sortable: true,
                    slot: 'custom_keyword'
                },
                {
                    title: this.$t('itemCompensate.paidOnCycle'),
                    sortable: true,
                    slot: 'paid_on_cycle'
                },
                {
                    title: this.$t('itemCompensate.taxable'),
                    key: 'is_taxable',
                    slot: 'is_taxable',
                    sortable: true,
                    align: 'center'
                },
                {
                    title: this.$t('itemCompensate.description'),
                    key: 'description',
                    minWidth: 400,
                    sortable: true
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    align: 'center',
                    fixed: 'right',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        }
    },
    methods: {
        addNew () {
            this.showForm = true
            this.$refs.form_action.clearInput()
            this.$refs.form_action.fetchResource()
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('payroll/itemCompensate/fetch', attributes)
                .catch(error => {
                    this.loading = false
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        clearEdit () {
            this.showForm = false
            this.$store.commit('payroll/itemCompensate/SET_EDIT_DATA', {})
        },
        onEdit (record) {
            this.showForm = true
            this.$store.commit('payroll/itemCompensate/SET_EDIT_DATA', record)
            this.$refs.form_action.setEditData()
            this.$refs.form_action.fetchResource()
        },
        onDelete (record) {
            record._deleting = true
            this.$store
                .dispatch(
                    'payroll/itemCompensate/destroy',
                    record.compensate_id
                )
                .then(response => {
                    this.fetchData()
                    record._deleting = false
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                    record._deleting = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'ITEM COMPENSATE',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({
                search
            })
        }, 500)
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>
