<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3">
                <label class="col-label form-label required tw-text-xs">{{
                    $t('itemCompensate.compensateItem')
                }}</label>
                <div>
                    <input
                        v-model="model.compensate_item"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': errors.has('compensate_item')
                        }"
                    />
                </div>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('compensate_item')"
                >
                    {{ errors.first('compensate_item') }}
                </div>
            </div>
            <div class="mb-3">
                <label class="col-label form-label tw-text-xs">{{
                    $t('itemCompensate.customRule')
                }}</label>
                <div>
                    <Select v-model="model.custom_keyword">
                        <Option
                            v-for="(itemCustomRule, index) in itemCustomRules"
                            :value="itemCustomRule.keyword"
                            :key="index"
                            :label="itemCustomRule.custom_rule"
                            >{{ itemCustomRule.custom_rule }}
                        </Option>
                    </Select>
                </div>
            </div>
            <div class="mb-3">
                <label class="form-label col-label">{{
                    $t('itemCompensate.paidOnCycle')
                }}</label>
                <div class="col-md-12">
                    <select v-model="model.paid_on_cycle" class="form-control">
                        <option :value="null">{{ $t('select') }}</option>
                        <option
                            v-for="c in paidOnCycle"
                            :key="c.value"
                            :value="c.value"
                        >
                            {{ c.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="mb-3">
                <label class="col-label form-label tw-text-xs">{{
                    $t('itemCompensate.description')
                }}</label>
                <textarea
                    v-model="model.description"
                    class="form-control"
                ></textarea>
            </div>
            <div class="mb-3">
                <Checkbox v-model="model.is_taxable">{{
                    $t('itemCompensate.taxable')
                }}</Checkbox>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t('cancel') }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t('saveAddNew') }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        :waiting="waiting"
                        :disabled="waiting_new || waiting"
                        @click.prevent="onSave"
                    >
                        {{ $t('save') }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t('update') }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin fix v-if="loading"></Spin>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    name: 'item-compensate',
    data () {
        return {
            loading: false,
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            paid_on_cycle: null,
            paidOnCycle: [
                {
                    value: 1,
                    text: 'First Cycle'
                },
                {
                    value: 2,
                    text: 'Second Cycle'
                }
            ],
            model: {
                compensate_item: null,
                is_taxable: false,
                description: null,
                custom_keyword: null
            }
        }
    },
    computed: {
        ...mapState('payroll/itemCompensate', ['edit_data']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        },
        itemCustomRules () {
            return this.$store.state.payroll.itemCompensate.itemCustomRules
        }
    },
    methods: {
        ...mapActions('payroll/itemCompensate', ['getItemCustomRule']),
        fetchResource () {
            this.loading = true
            this.getItemCustomRule()
                .then(() => {})
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('payroll/itemCompensate/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch('payroll/itemCompensate/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.compensate_item = this.edit_data.compensate_item
                this.model.custom_keyword = this.edit_data.custom_keyword
                this.model.paid_on_cycle = this.edit_data.paid_on_cycle
                this.model.is_taxable = this.edit_data.is_taxable
                this.model.description = this.edit_data.description
            }
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('payroll/itemCompensate/update', {
                    id: this.edit_data.compensate_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        clearInput () {
            this.errors = new Errors()
            this.model.compensate_item = null
            this.model.is_taxable = false
            this.model.description = null
            this.model.custom_keyword = null,
            this.model.paid_on_cycle = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'ITEM DEDUCTION',
                desc: not.text
            })
        }
    }
}
</script>
